import React, { Component } from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

class NewsPostTemplate extends Component {
  render() {
    const singleNews = this.props.data.contentfulNews
    return (
      <Layout>
        <Helmet title={`${singleNews.title}`} />
        <div className="inner-blog-post pad-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="post-content">
                  <h2 className="section-headline"> {singleNews.title} </h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleNews.description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NewsPostTemplate

export const pageQuery = graphql`
  query NewsPageQuery($slug: String) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    contentfulNews(slug: { eq: $slug }) {
      title
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
